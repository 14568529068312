<template>
	<v-progress-linear v-model="progress" class="updatecontrol"></v-progress-linear>
</template>

<script>
export default {
	name: 'updatecontrol',

	data() {
		return {
			isStopProgress: false,
			progress: 0,
			refreshSession: 0,
			interval: null
		};
	},

	props: {
		tempoRefresh: {
			type: Number,
			required: true
		}
	},

	methods: {
		startProgressBar() {
			this.isStopProgress = false;
			// start next interval
			this.interval = !this.interval ? setInterval(() => this.tick(), 1000) : this.interval;
		},

		stopProgressBar() {
			if (this.interval) {
				this.isStopProgress = true;
				this.progress = 0;
				clearInterval(this.interval);
				this.interval = null;
			}
		},

		pauseProgressBar() {
			if (this.interval) {
				clearInterval(this.interval);
				this.interval = null;
			}
		},

		resumeProgressBar() {
			this.interval = !this.interval ? setInterval(() => this.tick(), 1000) : this.interval;
		},

		resetProgressValue() {
			this.progress = 0;
		},

		keepSession() {
			this.$puiRequests.getRequest('/login/keepSessionActive');
		},

		tick() {
			this.progress += this.delta;

			if (this.progress <= 100) {
				return;
			}

			this.progress = 0;
			this.refreshSession += 1;
			this.$emit('update:refreshstops');

			if (this.refreshSession % 5 !== 0) {
				return;
			}

			this.refreshSession = 0;
			this.keepSession();
		},

		getPageVisibilityParams() {
			if (typeof document.hidden !== 'undefined') {
				// Opera 12.10 and Firefox 18 and later support
				return {
					hidden: 'hidden',
					visibilityChange: 'visibilitychange'
				};
			} else if (typeof document.msHidden !== 'undefined') {
				return {
					hidden: 'msHidden',
					visibilityChange: 'msvisibilitychange'
				};
			} else if (typeof document.webkitHidden !== 'undefined') {
				return {
					hidden: 'webkitHidden',
					visibilityChange: 'webkitvisibilitychange'
				};
			}
		},

		handleVisibilityChange() {
			const { hidden } = this.getPageVisibilityParams();

			if (document[hidden]) {
				this.pauseProgressBar();
				return;
			}

			if (!this.isStopProgress) {
				this.resumeProgressBar();
			}
		}
	},
	computed: {
		delta() {
			return 100 / this.tempoRefresh;
		}
	},
	created() {
		this.startProgressBar();

		const { visibilityChange } = this.getPageVisibilityParams();

		document.addEventListener(visibilityChange, this.handleVisibilityChange);

		this.$puiEvents.$on('update:refreshprogress', this.resetProgressValue);
	},
	destroyed() {
		clearInterval(this.interval);

		const { visibilityChange } = this.getPageVisibilityParams();

		document.removeEventListener(visibilityChange, this.handleVisibilityChange);

		this.$puiEvents.$off('update:refreshprogress', this.resetProgressValue);
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../styles/app-variables.pcss';
.updatecontrol {
	width: 100%;
	height: 2px !important;
	margin: 0px !important;
	margin-bottom: 10px !important;
	z-index: 0;
	& >>> .v-progress-linear__bar {
		background-color: rgba(255, 255, 255, 0);
	}
}
</style>
