const minToBow = 40;
const minToStern = 40;
const farSize = 10;

export default {
	/**
	 * Check how show be draw the feature
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	drawAsFarSize(feature, resolution) {
		var output = true;
		var dpr = this.getToBow(feature);
		var dpp = this.getToStern(feature);

		if ((dpr + dpp) / resolution > farSize) {
			output = false;
		}

		return output;
	},

	/**
	 * Distancia del receptor a proa
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	getToBow: function (feature) {
		if (feature.getProperties().to_bow !== undefined && feature.getProperties().to_bow !== null) {
			return feature.attributes.to_bow;
		}
		return minToBow;
	},

	/**
	 * Distancia del receptor a popa
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	getToStern: function (feature) {
		return feature.getProperties().to_stern || minToStern;
	}
};
