export const MAP_ID = 'map-id';

// MAP VARIABLES
export const CENTER = [0, 0];
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 19;
export const INITIAL_ZOOM = 1;
export const INITIAL_MAP_ANGLE = 0;
export const SOURCE_PROJECTION = 'EPSG:4326';
export const DESTINATION_PROJECTION = 'EPSG:3857';
