<template>
	<div>
		<GiswebToolbar :filter="this.filter" :isVisibleTimeFilter="this.isVisibleTimeFilter" @update:filter="updateFilter" />
		<v-row class="d-flex justify-space-around">
			<v-col class="listContainer" :cols="listContainerCols">
				<update-control v-if="tempoRefresh != null" :tempoRefresh="tempoRefresh" @update:refreshstops="getStops" />
				<div style="overflow-y: auto">
					<span class="d-flex">
						<GiswebExportBtn
							:modelName="model"
							:headers="headers"
							:items="this.visibleMap ? stopsProcessedByCreator : stopsToProcessed"
							:printMap="this.visibleMap"
							:mapManager="this.visibleMap ? mapManager : null"
						/>
						<pui-switch class="mr-2" @input="updateAllPanelsOpen" :label="$t('gisweb.entry.switchtitle')"></pui-switch>
						<v-btn class="elevation-0 mr-4" style="background-color: var(--low)" @click="orderStops">
							<v-icon small>fa-kit fa-line-order-1</v-icon>
						</v-btn>
						<v-btn class="elevation-0 mr-4" style="background-color: var(--low)" @click="toggleMap" v-if="this.isVisibleMap">
							<v-icon>{{ mapIcon }}</v-icon>
						</v-btn>
						<v-btn class="elevation-0" color="primary" @click="modalOpened = true">
							<v-icon small>fa-kit fa-line-information-consultation</v-icon>
						</v-btn>
					</span>
					<v-expansion-panels multiple flat tile outlined v-model="panel" v-if="localVisiblePanels">
						<v-expansion-panel
							v-for="(item, i) in this.visibleMap ? stopsProcessedByCreator : stopsToProcessed"
							:key="i"
							active-class="expansionactive"
							class="expansion"
						>
							<v-expansion-panel-header hide-actions>
								<CardHeader :stop="item" />
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<CardContent :stop="item" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
				<gisweb-legend v-model="modalOpened" />
			</v-col>
			<v-col cols="4" class="mapContainer" v-if="this.visibleMap">
				<gisweb-map
					:mapInitialVariables="mapInitialVariables"
					:tabKey="tabKey"
					:stopsToProcessed="this.stopsToProcessed"
					@update:stopsProcessedByCreator="updateStopsProcessedByCreator"
					@update:elementToZoom="resetElementToZoom"
					:elementToZoom="this.elementToZoom"
					@mapManagerLoaded="handleMapManagerLoaded"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import GiswebMap from './map/GiswebMap';

import UpdateControl from '../autorefresh/UpdateControl';
import GiswebToolbar from '../toolbar/GiswebToolbar.vue';
import CardHeader from './cards/CardHeader.vue';
import CardContent from './cards/CardContent.vue';
import GiswebLegend from '../legend/GiswebLegend.vue';
import { TAB_KEY_HISTORICAL } from '../utils/tabKeysConstants.js';
import GiswebExportBtn from './toolbar/GiswebExportBtn.vue';

export default {
	name: 'giswebtab',

	components: {
		GiswebToolbar,
		CardHeader,
		CardContent,
		GiswebLegend,
		UpdateControl,
		GiswebExportBtn,
		GiswebMap
	},

	mixins: [],

	data() {
		return {
			filter: {
				dateini: null,
				dateend: null,
				shiptype: [],
				berth: [],
				text: null
			},
			panel: [],
			stopsToProcessed: [],
			stopsProcessedByCreator: [],
			modalOpened: false,
			asc: true,
			visibleMap: this.isVisibleMap,
			localVisiblePanels: this.isVisiblePanels,
			allPanelsOpen: false,
			activityHeaders: [],
			modelConfiguration: null,
			isBulkExpand: false,
			elementToZoom: null,
			mapManager: null
		};
	},

	props: {
		model: {
			type: String,
			required: true
		},
		isVisibleMap: {
			type: Boolean,
			default: true
		},
		isVisibleTimeFilter: {
			type: Boolean,
			default: true
		},
		isVisiblePanels: {
			type: Boolean,
			default: true
		},
		tabKey: {
			type: String,
			required: true
		},
		tempoRefresh: {
			type: Number,
			required: true
		},
		mapInitialVariables: {
			type: Object,
			required: false
		}
	},

	computed: {
		headers() {
			return [...this.masterProviderHeaders(), ...this.activityHeaders];
		},
		mapIcon() {
			return this.visibleMap ? 'fa-sharp fa-solid fa-expand' : 'fa-sharp fa-solid fa-compress';
		},
		listContainerCols() {
			return this.visibleMap ? 7 : 11;
		},
		activeTab() {
			return this.$store.getters.getTabModel;
		}
	},

	created() {
		if (this.localVisiblePanels) {
			this.getStops();
		}
	},

	methods: {
		updateFilter(newFilter) {
			// Ponemos el update control a 0 cada vez que se canvia el filtro
			this.$puiEvents.$emit('update:refreshprogress');

			this.filter = newFilter;
			if (this.tabKey === TAB_KEY_HISTORICAL) {
				const hasValidDateFilter = this.filter.dateini && this.filter.dateend;
				this.localVisiblePanels = hasValidDateFilter;
				if (hasValidDateFilter) {
					this.getStops();
				}
			} else {
				this.getStops();
			}
		},
		buildFilterRules() {
			const rules = [];

			this.filter.dateini ? rules.push({ field: 'eta', op: 'ge', data: this.filter.dateini }) : null;
			this.filter.dateend ? rules.push({ field: 'eta', op: 'le', data: this.filter.dateend }) : null;
			Array.isArray(this.filter.shiptype) && this.filter.shiptype.some((b) => b > 0)
				? rules.push({ field: 'vesseltype', op: 'in', data: this.filter.shiptype })
				: [];
			Array.isArray(this.filter.berth) && this.filter.berth.some((b) => b > 0)
				? rules.push({ field: 'berthid', op: 'in', data: this.filter.berth })
				: [];

			// Add port autority filter
			const portAuthority = this.$store.getters.getPortAuthorityId;
			rules.push({ field: 'port_authority', op: 'eq', data: portAuthority });

			return rules;
		},

		buildSearchRules() {
			const textFilter = this.filter.text || '';
			const fields = [
				'portcallnumber',
				'vesselname',
				'berthname',
				'consigneename',
				'vessellength',
				'operationtype',
				'loadtypename',
				'quantity',
				'stevedorecompanyname'
			];

			return fields.map((field) => ({
				field,
				op: 'cn',
				data: textFilter
			}));
		},
		buildGroupPa() {
			return {
				groups: [],
				groupOp: 'or',
				rules: this.buildSearchRules()
			};
		},
		getStops() {
			const rules = this.buildFilterRules();
			const groupPa = this.buildGroupPa();

			const opts = {
				model: this.model,
				filter: {
					groups: [groupPa],
					groupOp: 'and',
					rules: rules
				},
				order: [
					{
						column: 'eta',
						direction: 'asc'
					}
				]
			};
			this.fetchStops(opts);
		},
		fetchStops(opts) {
			this.$puiRequests.postRequest('/vstopoperations/getStopsGisweb', opts, (response) => {
				this.stopsToProcessed = response.data.data;
				this.updateActivityHeaders();
			});
			this.modelConfiguration = this.$store.getters.getModelByName('stopoperations');
		},
		updateActivityHeaders() {
			const rowsWithActivities = this.stopsProcessedByCreator.filter((row) => row.resourcesjson != null);

			if (this.isEmpty(this.activityHeaders) && rowsWithActivities.length > 0) {
				this.activityHeaders = JSON.parse(rowsWithActivities[0].resourcesjson)
					.map(mapActivityHeaders)
					.filter((row) => row.disabled !== 1);
			}
		},
		orderStops() {
			this.asc = !this.asc; // Alterna el valor de asc
			if (this.asc) {
				this.stopsProcessedByCreator.sort((a, b) => {
					return new Date(a.eta) - new Date(b.eta); // Orden ascendente
				});
			} else {
				this.stopsProcessedByCreator.sort((a, b) => {
					return new Date(b.eta) - new Date(a.eta); // Orden descendente
				});
			}
		},
		toggleMap() {
			this.visibleMap = !this.visibleMap;
		},
		togglePanels() {
			this.isBulkExpand = true;
			if (this.allPanelsOpen) {
				this.panel = this.visibleMap ? this.stopsProcessedByCreator.map((_, i) => i) : stopsToProcessed.map((_, i) => i);
			} else {
				this.panel = [];
			}
			this.isBulkExpand = false;
		},
		updateAllPanelsOpen(value) {
			this.allPanelsOpen = value;
			this.togglePanels();
		},
		masterProviderHeaders() {
			return this.modelConfiguration
				? this.modelConfiguration?.columns
						?.filter((column) =>
							['portcallnumber', 'vesselname', 'berthname', 'eta', 'etd', 'consigneename', 'vessellength'].includes(column.name)
						)
						.map((visibleColumn) => {
							return { text: this.$t(visibleColumn.title), value: visibleColumn.name, columntype: visibleColumn.type };
						})
				: [];
		},
		isEmpty(...values) {
			return values.some(
				(value) =>
					value === undefined ||
					value === null ||
					(typeof value === 'string' && value.trim() === '') ||
					(Array.isArray(value) && value.length === 0)
			);
		},
		isActiveTab() {
			// Reemplaza esto con la lógica que determine si este es el tab activo
			return this.activeTab === this.tabKey;
		},
		updateStopsProcessedByCreator(stops) {
			this.stopsProcessedByCreator = stops;
		},
		resetElementToZoom() {
			this.elementToZoom = null;
		},
		handleMapManagerLoaded(mapManager) {
			this.mapManager = mapManager;
		}
	},
	watch: {
		'$store.state.gisweb.tabmodel'() {
			// Ponemos el update control a 0 cada vez que se canvia el tabmodel
			this.$puiEvents.$emit('update:refreshprogress');
			this.filter = {
				dateini: null,
				dateend: null,
				shiptype: [],
				berth: [],
				text: null
			};
			if (this.isActiveTab() && this.localVisiblePanels) {
				this.getStops();
			}
		},
		panel(newVal, oldVal) {
			if (this.isBulkExpand) {
				return; // Ignora cambios masivos
			}

			// Detectar qué panel se expandió o colapsó
			const expandedPanels = newVal.filter((panel) => !oldVal.includes(panel));

			if (expandedPanels.length === 1) {
				const expandedPanelIndex = expandedPanels[0];
				// Zoom to element
				this.elementToZoom = this.visibleMap ? this.stopsProcessedByCreator[expandedPanelIndex] : stopsToProcessed[expandedPanelIndex];
			}
		}
	}
};
</script>

<style>
@import '../../../styles/app-variables.pcss';
.listContainer {
	border-radius: 1%;
	overflow: auto;
	height: calc(100vh - 230px);
}

.expansion {
	border-radius: 8px !important;
	padding-bottom: 0px !important;
	border: 1px solid var(--low) !important;
	margin-bottom: 10px;
}

.expansionactive {
	border-radius: 8px !important;
	background-color: #d7ebff !important;
	border: 1px solid var(--primary) !important;
}
</style>
