<template>
	<div class="mx-4">
		<v-tabs v-model="tabmodel" slider-color="primary" @change="updateTabModel">
			<v-tab v-for="tab in filteredTabs" :key="tab.key" :href="`#${tab.key}`">
				{{ $t(tab.title) }}
			</v-tab>
		</v-tabs>

		<v-divider class="mb-4"></v-divider>

		<v-tabs-items v-model="tabmodel">
			<v-tab-item v-for="tab in tabs" :key="tab.key" :value="tab.key">
				<GiswebTab
					:mapInitialVariables="mapInitialVariables"
					:tempoRefresh="tempoRefresh"
					:isVisiblePanels="tab.isVisiblePanels"
					:filter="filter"
					:model="tab.model"
					:isVisibleMap="tab.isVisibleMap"
					:isVisibleTimeFilter="tab.isVisibleTimeFilter"
					:tabKey="tab.key"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import GiswebTab from './sections/GiswebTab.vue';
import {
	MODEL_STOPENTRY,
	MODEL_STOPEXIT,
	MODEL_STOPFORECAST,
	MODEL_STOPANNOUNCED,
	MODEL_STOPANCHORAGE,
	MODEL_STOPINITIATED,
	MODEL_STOPHISTORICAL
} from './utils/modelsConstants.js';

import {
	TAB_KEY_ENTRY,
	TAB_KEY_EXIT,
	TAB_KEY_FORECAST,
	TAB_KEY_ANNOUNCED,
	TAB_KEY_ANCHORAGE,
	TAB_KEY_INITIATED,
	TAB_KEY_HISTORICAL
} from './utils/tabKeysConstants.js';

import { MIN_ZOOM, MAX_ZOOM, INITIAL_ZOOM, INITIAL_MAP_ANGLE, SOURCE_PROJECTION, DESTINATION_PROJECTION } from './utils/mapVariables.js';

export default {
	name: 'giswebcontainer',

	components: {
		GiswebTab
	},

	computed: {
		filteredTabs() {
			return this.tabs.filter((tab) => this.hasFunctionality(tab.functionality));
		}
	},

	data() {
		return {
			mapInitialVariables: null,
			defaultTempoRefresh: 60,
			tempoRefresh: 60,
			filter: {
				dateini: null,
				dateend: null,
				shiptype: [],
				berth: [],
				text: null
			},
			tabmodel: this.$store.getters.getTabModel,
			tabs: [
				{
					key: TAB_KEY_ENTRY,
					title: 'gisweb.entry.title',
					functionality: 'READ_GISWEB_ENTRY',
					model: MODEL_STOPENTRY,
					isVisibleTimeFilter: false
				},
				{
					key: TAB_KEY_EXIT,
					title: 'gisweb.exit.title',
					functionality: 'READ_GISWEB_EXIT',
					model: MODEL_STOPEXIT,
					isVisibleTimeFilter: false
				},
				{
					key: TAB_KEY_FORECAST,
					title: 'gisweb.forecast.title',
					functionality: 'READ_GISWEB_FORECAST',
					model: MODEL_STOPFORECAST,
					isVisibleTimeFilter: false,
					isVisibleMap: false
				},
				{
					key: TAB_KEY_ANNOUNCED,
					title: 'gisweb.announced.title',
					functionality: 'READ_GISWEB_ANNOUNCED',
					model: MODEL_STOPANNOUNCED,
					isVisibleMap: false
				},
				{
					key: TAB_KEY_ANCHORAGE,
					title: 'gisweb.anchorage.title',
					functionality: 'READ_GISWEB_ANCHORAGE',
					model: MODEL_STOPANCHORAGE
				},
				{
					key: TAB_KEY_INITIATED,
					title: 'gisweb.initiated.title',
					functionality: 'READ_GISWEB_INITIATED',
					model: MODEL_STOPINITIATED
				},
				{
					key: TAB_KEY_HISTORICAL,
					title: 'gisweb.historical.title',
					functionality: 'READ_GISWEB_HISTORICAL',
					model: MODEL_STOPHISTORICAL,
					isVisiblePanels: false,
					isVisibleMap: false
				}
			]
		};
	},

	created() {
		this.getTempoRefreshAuthority();
		this.getExtensions();
	},

	destroyed() {
		this.$store.commit('setTabModel', TAB_KEY_ENTRY);
	},

	methods: {
		updateTabModel(newTab) {
			this.$store.commit('setTabModel', newTab);
		},

		hasFunctionality(functionality) {
			return this.$store.getters.hasFunctionality(functionality);
		},

		getTempoRefreshAuthority() {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'TEMPO_REFRESH_MAP'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: window.localStorage.getItem('workingPortAuthorityId')
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length > 0) {
					this.tempoRefresh = Number(response.data.data[0].value) || this.defaultTempoRefresh;
				} else {
					this.tempoRefresh = this.defaultTempoRefresh;
				}
			});
		},
		/**
		 * Extension
		 */
		getExtensions() {
			const workingPortsId = window.localStorage.getItem('workingPortsId');

			if (workingPortsId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: workingPortsId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					this.portInfo = response.data.data[0];
					this.portExtensions = response.data.data.map((object) => {
						const { id, portlocode, portauthorityname, bbox } = object;
						return { id, portlocode, portauthorityname, bbox };
					});

					this.mapInitialVariables = {
						CENTER: [this.portInfo.longitude, this.portInfo.latitude],
						MIN_ZOOM: MIN_ZOOM,
						MAX_ZOOM: MAX_ZOOM,
						INITIAL_ZOOM: INITIAL_ZOOM,
						INITIAL_MAP_ANGLE: INITIAL_MAP_ANGLE,
						MAX_EXTENT: this.portInfo.bboxextent,
						SOURCE_PROJECTION: SOURCE_PROJECTION,
						DESTINATION_PROJECTION: DESTINATION_PROJECTION
					};
				});
			}
		}
	}
};
</script>

<style scoped></style>
