import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import WKT from 'ol/format/WKT';
import CalcularPuntoAtraqueWorker from 'worker-loader!./../workers/calcularPuntoAtraque';
import BoatBuilder from '../builder/boatShapeBuilder';
import mapFeatureStyleUtils from '../utils/mapFeatureStyleUtils';
import { GISWEB_STOPS_LAYER_ID } from './../constants/layers';

class GiswebStopsFeatureCreator {
	berthBoatsMap = {};
	/**
	 * @property {Number} workerController
	 * it controlls the worker index for the next processing stop, used internally only
	 */

	workerController = 0;
	/**
	 * @property {Number} numOfWorkers
	 * it defines the number of workers for calcularPuntoAtraque
	 */
	numOfWorkers = 1;
	/**
	 * @property {Number} distAbarloar
	 * it defines the amount of meters in projection to leave between boats
	 */
	distAbarloar = 10;
	/**
	 * @property {Array} calcularPuntoAtraqueWorker
	 * the array containing the workers st by the numOfWorkers
	 */
	calcularPuntoAtraqueWorker = null;

	berthLayer = null;
	bollardLayer = null;

	constructor(onStopsProcessed, numOfWorkers = 1, distAbarloar = 10) {
		this.numOfWorkers = numOfWorkers;
		this.calcularPuntoAtraqueWorker = [];
		this.distAbarloar = distAbarloar;
		this.onStopsProcessed = onStopsProcessed;
		for (var i = 0; i < this.numOfWorkers; i++) {
			this.calcularPuntoAtraqueWorker.push(new CalcularPuntoAtraqueWorker());
		}
	}

	setMapUtils(mapUtils) {
		this.mapUtils = mapUtils;
	}

	setMapManager(mapManager) {
		this.mapManager = mapManager;
	}

	getMapManager() {
		return this.mapManager;
	}

	setBerthLayer(berthLayer) {
		this.berthLayer = berthLayer;
	}

	setBollardLayer(bollardLayer) {
		this.bollardLayer = bollardLayer;
	}

	// LAYER
	columnsPopup = [
		{ property: 'vesselname', label: 'gisweb.detail.shipdata.vesselname', isTitle: true },
		{ property: 'portcallnumber', label: 'gisweb.detail.stopdata.portcallnumber' },
		{ property: 'vesselimo', label: 'gisweb.detail.shipdata.vesselimo' },
		{ property: 'vesseltypedescription', label: 'gisweb.detail.shipdata.vesseltype' },
		{ property: 'berthname', label: 'gisweb.entry.berthname' },
		{ property: 'status', label: 'gisweb.detail.stopdata.status' },
		{ property: 'etaconverted', label: 'gisweb.entry.eta' },
		{ property: 'etdconverted', label: 'gisweb.entry.etd' },
		{ property: 'consigneename', label: 'gisweb.entry.consignee' },
		{ property: 'destinationCode', label: 'gisweb.detail.operationsdata.loadtypename' },
		{ property: 'operationsPopup', label: 'gisweb.detail.operationsdata.operationtype' }
	];

	stopsSource = new VectorSource({
		features: []
	});

	stopsLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: GISWEB_STOPS_LAYER_ID,
			columnsPopup: this.columnsPopup
		},
		source: this.stopsSource
	});

	getStopsLayer() {
		return this.stopsLayer;
	}

	/**
	 *
	 * @param {Array<Berths>} berths The arrayList as it comes from the operationscarto
	 * @returns {Array<Feature>} berthsFeatures Openlayers Features for a vector layer
	 */

	processBerths(berths) {
		const format = new WKT();
		var berthFeatures = berths
			.map((berth) => {
				try {
					// Angulo perpendicular
					berth.slope = Math.PI / 2 - berth.azimuth * (Math.PI / 180);
					//dejamos que caiga en rango de 0 a 2PI
					if (berth.slope < 0) {
						berth.slope = berth.slope + 2 * Math.PI;
					}
					const feat = format.readFeature(berth.thegeom, {
						dataProjection: 'EPSG:4326',
						featureProjection: 'EPSG:3857'
					});
					const firstBerthVertex = this.mapUtils.createPoint(...feat.getGeometry().flatCoordinates);
					const lastBerthVertex = this.mapUtils.createPoint(...feat.getGeometry().flatCoordinates.slice(2));
					const berthMiddlePoint = this.mapUtils.getMiddlePoint(firstBerthVertex, lastBerthVertex);
					berth.middle = berthMiddlePoint;
					berth.iniPoint = firstBerthVertex;
					berth.endPoint = lastBerthVertex;
					feat.setProperties(berth);
					feat.setId(berth.id);
					feat.type = 'LineString';
					return feat;
				} catch (e) {
					console.info('muelle no añadido: ' + berth.id);
				}
			})
			.filter((feat) => !feat === false);

		return berthFeatures;
	}
	/**
	 *
	 * @param {Array<Bollards>} bollards The arrayList as it comes from the operationscarto
	 * @returns {Array<Feature>} bollardsFeatures Openlayers Features for a vector layer
	 */

	processBollards(bollards) {
		const format = new WKT();
		const berthIds = Object.keys(bollards);
		const bollardFeatures = [];
		berthIds.forEach((berthId) => {
			bollards[berthId].forEach((bol) => {
				try {
					const feat = format.readFeature(bol.thegeom, {
						dataProjection: 'EPSG:4326',
						featureProjection: 'EPSG:3857'
					});
					feat.setProperties(bol);
					feat.setId(bol.id);
					bollardFeatures.push(feat);
					bol.x = feat.values_.geometry.flatCoordinates[0];
					bol.y = feat.values_.geometry.flatCoordinates[1];
				} catch (e) {
					console.info('bolardo no añadido: ' + bol.id);
				}
			});
		});
		return bollardFeatures;
	}

	processStops(stops) {
		const self = this;
		console.log('processStops: ' + stops.length);
		// Limpiamos los stoops anteriores
		this.getStopsLayer().getSource() && this.getStopsLayer().getSource().clear();
		// TODO: Cada vez que llamamos a processStops paramos los que se este procesando
		this.stopsToProcess = stops.length;
		stops.forEach(function (stop) {
			if (stop.vesselbeam == null || stop.vessellength == null) {
				console.log('No tenemos las dimensiones del barco para la escala: ' + stop.portcallnumber);
				self._checkStopsProcessed();
			} else {
				self._processStop(stop);
			}
		});
	}

	/**
	 *
	 * @param {Object} stopThe stop object to be displayed as a Boat
	 * @param {Object} puntoAtraque The centroid of the boat to create it (only called by recursion, when method @_needsAbarloarBoat returns a number)
	 */

	async _processStop(stop, puntoAtraque, distAbarloar) {
		try {
			const berthFeat = this.berthLayer.getSource().getFeatureById(stop.berthid);
			if (!berthFeat) {
				console.log('muelle no encontrado: ' + stop.berthid);
				return;
			}
			let bolIni =
				stop.bollardini !== null && stop.bollardini !== undefined ? this.bollardLayer.getSource().getFeatureById(stop.bollardini) : false;
			let bolEnd =
				stop.bollardend !== null && stop.bollardend !== undefined ? this.bollardLayer.getSource().getFeatureById(stop.bollardend) : false;
			let bolIniFindedFlag = false;
			let bolEndFindedFlag = false;
			let middePoint = berthFeat.get('middle');
			const lengthMercator = this.mapUtils.getMetersMercator(middePoint.y, stop.vessellength);
			const distanceRatioForThisLat = lengthMercator / stop.vessellength;
			const beamMercator = stop.vesselbeam * distanceRatioForThisLat;
			if (!bolIni) {
				bolIni = berthFeat.get('iniPoint');
			} else {
				bolIni = this.mapUtils.createPoint(...bolIni.getGeometry().flatCoordinates);
				bolIniFindedFlag = true;
			}
			if (!bolEnd) {
				bolEnd = berthFeat.get('endPoint');
			} else {
				bolEndFindedFlag = true;
				bolEnd = this.mapUtils.createPoint(...bolEnd.getGeometry().flatCoordinates);
			}
			if (bolIniFindedFlag && bolEndFindedFlag) {
				middePoint = this.mapUtils.getMiddlePoint(bolIni, bolEnd);
			}

			var tipoAtraque = 'C';
			var dirAtraque = 'E';

			if (stop.docksideways != null) {
				if (stop.docksideways) {
					// COSTADO
					tipoAtraque = 'C';
					dirAtraque = stop.portstarboard || 'E';
				} else {
					// PUNTA
					tipoAtraque = 'P';
					dirAtraque = stop.bowstern ? stop.bowstern : 'B';
				}
			}

			var argumentObject = {
				fid: stop.id,
				offset: 20 * distanceRatioForThisLat,
				ptoMedio: middePoint,
				dirAtraque: dirAtraque, // E: estribor, B: babor (para caso de costado) B: proa, S: popa (caso de punta)
				tipoAtraque: tipoAtraque, // C: costado,  P: punta
				tiposAtraque: this.tiposAtraque,
				agua: berthFeat.get('seaside'),
				alfa: berthFeat.get('slope'),
				esloraMercatorDiv2: lengthMercator,
				mangaMercatorDiv2: beamMercator,
				distAbarloar: distAbarloar * distanceRatioForThisLat || this.distAbarloar * distanceRatioForThisLat,
				attributes: Object.assign({}, stop),
				puntoAtraque
			};
			stop.alfa = argumentObject.alfa;
			const worker = this._getWorker(this.calcularPuntoAtraqueWorker);
			worker.postMessage(argumentObject);
			if (worker.onmessage === undefined || worker.onmessage === null) {
				worker.onmessage = (msg) => {
					if (!msg.data) {
						console.log('No message data boat');
						this._checkStopsProcessed();
					} else {
						this._createBoat(msg.data);
					}
				};
			}
		} catch (e) {
			console.log(stop);
			console.log('muelle no encontrado: ' + stop.berthid);
			this._checkStopsProcessed();
		}
	}

	_onStopsProcessed(callback) {
		this.onStopsProcessed();
	}

	_createBoat(stopObj) {
		const stop = stopObj.attributes;
		if (isNaN(stopObj.poly.boat[0][0])) {
			console.log('Stop with no coordinates boat: ' + stopObj.attributes.vesselname + ' idLayer: ' + stopObj.attributes.idLayer);
			this._checkStopsProcessed();
			return;
		}

		const boatPolygonsObj = BoatBuilder.createBoat(stopObj.poly.boat, stopObj.poly.bounds);
		const boatFeature = new Feature(boatPolygonsObj.boat);

		const boatBoundsGeometry = boatPolygonsObj.bounds;
		stop.puntoAtraque = stopObj.puntoAtraque;
		stop.polyBounds = stopObj.poly.boat;
		stop.lon = stopObj.puntoAtraque.x;
		stop.lat = stopObj.puntoAtraque.y;
		stop.featureVisible = true;
		stop.clickFeature = false;
		stop.showpopup = true;
		stop.layerId = GISWEB_STOPS_LAYER_ID;
		stop.etaconverted = this.convertDate(stop.eta);
		stop.etdconverted = this.convertDate(stop.etd);
		var operationsPopup = '';
		stop.operations.forEach((operation) => {
			operationsPopup = operationsPopup + operation.operationtype + ' / ' + operation.loadtypename;
			// sino es el iltimo añadimos un salto de linea
			if (operation !== stop.operations[stop.operations.length - 1]) {
				operationsPopup += '<br>';
			}
		});
		stop.operationsPopup = operationsPopup;

		boatFeature.setProperties(stop);

		const berthid = stop.berthid;
		if (Object.prototype.hasOwnProperty.call(this.berthBoatsMap, berthid)) {
			const abarloar = this._needsAbarloarBoat(stop, boatPolygonsObj.bounds, berthid);
			if (abarloar !== false) {
				this._processStop(stopObj.attributes, stopObj.puntoAtraque, abarloar + this.distAbarloar);
				return;
			}
		}
		// Aplicar estilo a barcos.
		const resolution = this.mapManager.getMapInstance().getView().getResolution();
		mapFeatureStyleUtils.setStyleFeatureVesselBbdd(boatFeature, resolution, boatPolygonsObj.boat, stopObj.puntoAtraque.x, stopObj.puntoAtraque.y);
		boatFeature.changed();

		this.getStopsLayer().getSource().addFeature(boatFeature);

		this._checkStopsProcessed();
		this._addBoatToBerthBoatsMap(stop, boatBoundsGeometry);
	}

	convertDate(date) {
		// Crear un objeto Date
		const fecha = new Date(date);

		// Obtener los componentes de la fecha
		const dia = fecha.getDate().toString().padStart(2, '0');
		const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses son base 0
		const anio = fecha.getFullYear();

		const horas = fecha.getHours().toString().padStart(2, '0');
		const minutos = fecha.getMinutes().toString().padStart(2, '0');

		// Formatear la fecha
		const fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}`;
		return fechaFormateada;
	}

	_checkStopsProcessed() {
		if (this.stopsToProcess !== 0) {
			this.stopsToProcess--;
			if (this.stopsToProcess === 0) {
				this._onStopsProcessed();
			}
		}
	}

	_getWorker(workersArray) {
		this.workerController++;
		if (this.workerController === workersArray.length) {
			this.workerController = 0;
		}
		return workersArray[this.workerController];
	}

	_addBoatToBerthBoatsMap(stop, boatBounds) {
		if (!Object.prototype.hasOwnProperty.call(this.berthBoatsMap, stop.berthid)) {
			this.berthBoatsMap[stop.berthid] = [];
		}
		const tipatr = stop.tipatr;
		let offset = stop.vesselbeam;
		if (tipatr === 'P') {
			offset = stop.vessellength;
		}
		this.berthBoatsMap[stop.berthid].push({ idStop: stop.id, bounds: boatBounds, offset });
	}

	_needsAbarloarBoat(stop, boatFeature, berthid) {
		for (let length = this.berthBoatsMap[berthid].length, i = 0; i < length; i++) {
			const exitentBoatiInBerthBoundsFeature = this.berthBoatsMap[berthid][i];
			// Si soy el mismo barco puedo solaparme. (esto es para cuando movemos un barco)
			if (stop.id == exitentBoatiInBerthBoundsFeature.idStop) {
				continue;
			}
			const intersectsExistentBoat = mapUtils.overlaps(exitentBoatiInBerthBoundsFeature.bounds, boatFeature);
			if (intersectsExistentBoat === true) {
				return exitentBoatiInBerthBoundsFeature.offset || false;
			}
		}
		return false;
	}

	terminateWorkers() {
		this.calcularPuntoAtraqueWorker.forEach((worker) => {
			worker.terminate();
		});
	}

	clearBerthBoatsMap() {
		this.berthBoatsMap = {};
	}
}

export default GiswebStopsFeatureCreator;
