<template>
	<div class="map-container">
		<pui-maps-renderer
			:ref="dynamicMapRef"
			v-if="mapInitialVariables"
			:mapInitialVariables="mapInitialVariables"
			:basePathProduct="basePathProduct"
			:framesData="framesData"
			:legendItemsBody="legendItemsBody"
			:legendItemsDialog="legendItemsDialog"
			:isVisiblePanels="isVisiblePanels"
			@puimaps:mapIsLoaded="afterMapIsLoaded"
		></pui-maps-renderer>
	</div>
</template>

<script>
import mapFeatureStyleUtils from './utils/mapFeatureStyleUtils';
import mapInitialVariables from '../../utils/mapVariables.js';
import {
	// BERTHS AND BOLLARDS
	BERTHS_LAYER_ID,
	BOLLARDS_LAYER_ID,
	// Gisweb Stops
	GISWEB_STOPS_LAYER_ID
} from './constants/layers';

import CartoMixin from '@/mixins/CartoMixin.js';

/** Creators */
import GiswebStopsFeatureCreator from './creators/GiswebStopsFeatureCreator.js';

/** Styles */
import bollardStyle from '@/components/operations/map/styles/bollardStyle';
import berthStyle from '@/components/operations/map/styles/berthStyle';

/** 3rd-party */
import { Polygon } from 'ol/geom';

const optsBollardsBerthsLayer = {
	minZoom: 16,
	zIndex: 250 // Por encima de los barcos 100-200
};

export default {
	name: 'giswebmapexit',

	mixins: [CartoMixin],

	data() {
		return {
			framesData: null,
			legendItemsBody: null,
			legendItemsDialog: null,
			basePathProduct: this.$route.path,
			mapManager: null,
			isBerthsAddedLayer: false,
			isBollardsAddedLayer: false,
			isVisiblePanels: false,
			portInfo: null,
			portExtensions: null
		};
	},

	props: {
		stopsToProcessed: {
			type: Array,
			required: true
		},

		tabKey: {
			type: String,
			required: false
		},

		mapInitialVariables: {
			type: Object,
			required: false
		},

		elementToZoom: {
			type: Object,
			required: false
		}
	},

	computed: {
		berths() {
			return this.$store.getters.berths;
		},

		bollards() {
			return this.$store.getters.bollards;
		},
		activeTab() {
			return this.$store.getters.getTabModel;
		},
		dynamicMapRef() {
			// Genera una referencia única basada en el tabKey
			return `mapRenderer_${this.tabKey}`;
		}
	},

	destroyed() {
		this.giswebStopsFeatureCreator.terminateWorkers();
	},

	methods: {
		// Obtiene la instancia de mapManager
		afterMapIsLoaded() {
			const mapRenderer = this.$refs[this.dynamicMapRef];
			if (mapRenderer) {
				this.mapManager = Object.assign({}, mapRenderer.mapManager);
				this.$emit('mapManagerLoaded', this.mapManager);
				this.mapUtils = mapRenderer.mapUtils;
				this.giswebStopsFeatureCreator = new GiswebStopsFeatureCreator((stopsCreated) => this.onStopsCreated(stopsCreated), 1, 10);
				this.giswebStopsFeatureCreator.setMapUtils(this.mapUtils);
				this.giswebStopsFeatureCreator.setMapManager(this.mapManager);
				this.setupMap();
			}
		},

		setupMap() {
			// Register map events
			this.registerMapEvents();
			// Add berts and bollards layers
			this.addBerthsLayer();
			this.addBollardsLayer();
			// Add stops layer
			this.addStopsLayer();
			// Process stops iniatially
			this.processStops(this.stopsToProcessed);
		},

		/**
		 * Map events
		 */
		registerMapEvents() {
			var self = this;
			this.mapManager
				.getMapInstance()
				.getView()
				.on(
					'change:resolution',
					(evt) => {
						var resolution = evt.target.getResolution();
						self.changeFeaturesStyle(resolution);
					},
					{
						layerFilter: (layer) => layer instanceof VectorLayer
					}
				);
		},

		changeFeaturesStyle(resolution) {
			this.mapManager.getLayerById(GISWEB_STOPS_LAYER_ID) &&
				this.mapManager.getLayerById(GISWEB_STOPS_LAYER_ID).getSource() &&
				this.mapManager
					.getLayerById(GISWEB_STOPS_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y
						);
						feature.changed();
					});
		},

		addStopsLayer() {
			const stopsLayer = this.giswebStopsFeatureCreator.getStopsLayer();
			this.giswebStopsFeatureCreator.getMapManager().addLayer(stopsLayer);
		},

		addBerthsLayer() {
			const berthLayer = this.giswebStopsFeatureCreator
				.getMapManager()
				.createVectorLayerFeatures(BERTHS_LAYER_ID, [], berthStyle, optsBollardsBerthsLayer);
			this.giswebStopsFeatureCreator.getMapManager().addLayer(berthLayer);
			const berthsFeatures = this.giswebStopsFeatureCreator.processBerths(this.berths);
			this.giswebStopsFeatureCreator.setBerthLayer(this.giswebStopsFeatureCreator.getMapManager().getLayerById(BERTHS_LAYER_ID));
			this.giswebStopsFeatureCreator.getMapManager().addFeaturesToVectorLayer(BERTHS_LAYER_ID, berthsFeatures);
			this.isBerthsAddedLayer = true;
		},

		addBollardsLayer() {
			const bollardsLayer = this.giswebStopsFeatureCreator
				.getMapManager()
				.createVectorLayerFeatures(BOLLARDS_LAYER_ID, [], bollardStyle, optsBollardsBerthsLayer);
			this.giswebStopsFeatureCreator.getMapManager().addLayer(bollardsLayer);
			const bollardFeatures = this.giswebStopsFeatureCreator.processBollards(this.bollards);
			this.giswebStopsFeatureCreator.setBollardLayer(this.giswebStopsFeatureCreator.getMapManager().getLayerById(BOLLARDS_LAYER_ID));
			this.giswebStopsFeatureCreator.getMapManager().addFeaturesToVectorLayer(BOLLARDS_LAYER_ID, bollardFeatures);
			this.isBollardsAddedLayer = true;
		},

		processStops(stops) {
			stops.length && this.isBerthsAddedLayer && this.isBollardsAddedLayer && this.giswebStopsFeatureCreator.processStops(stops);
		},

		onStopsCreated() {
			const features = this.giswebStopsFeatureCreator
				.getMapManager()
				.getFeaturesLayerById(GISWEB_STOPS_LAYER_ID)
				.map((feature) => {
					return feature.getProperties();
				});
			this.$emit('update:stopsProcessedByCreator', features);
		}
	},

	watch: {
		stopsToProcessed(stops) {
			this.processStops(stops);
		},

		elementToZoom() {
			if (this.elementToZoom !== null) {
				const zoom = 16;
				this.mapManager.centerInCoordinates([this.elementToZoom.lon, this.elementToZoom.lat], zoom);
				this.$emit('update:elementToZoom');
			}
		}
	}
};
</script>

<style scoped>
.map-container {
	height: calc(100vh - 230px);
	position: relative;
	border-radius: 8px;
	overflow: hidden;
}

.ol-attribution {
	left: 0px;
}
</style>
